<template>
  <ul class="social-ul">
    <li>
      <a href="https://github.com/gbumanzordev" target="_blank">
        <i class="fab fa-github"></i
      ></a>
    </li>
    <li>
      <a href="https://twitter.com/gbumanzordev" target="_blank">
        <i class="fab fa-twitter"></i
      ></a>
    </li>
    <li>
      <a href="https://linkedin.com/in/bumanzor" target="_blank">
        <i class="fab fa-linkedin"></i
      ></a>
    </li>
    <li>
      <a href="https://instagram.com/gbumanzordev" target="_blank">
        <i class="fab fa-instagram"></i
      ></a>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.social-ul {
  @apply grid grid-cols-4 my-4 py-2;

  li {
    @apply text-4xl mr-8;
  }
}
</style>
